import React, { useState } from "react";
import useWeb from "../custom/useWeb";

const Reason = (props) => {
  const [other, setOther] = useState(false);
  const { data } = useWeb("get_reason_for_joining");
  const handleChange = (e) => {
    if (e.target.value === "other") {
      setOther(true);
    } else {
      setOther(false);
    }
    props.onChange(e);
  };
  const handleOther = (e) => {
    props.onChange(e);
  };
  return (
    <>
      <div className="row col" style={{ margin: "0px" }}>
        {/* {console.log(data)} */}
        <select
          className="form-select form-select-lg mb-3"
          name="reason"
          onChange={handleChange}
          defaultValue=""
          value={
            props.dataForm &&
            (props.dataForm === null ? "" : props.dataForm.reason)
          }
        >
          <option key="0" value="" disabled>
            -
          </option>
          {data &&
            data.message.map((d) => (
              <>
                <option key={d[0]} value={d[1]}>
                  {d[1]}
                </option>
              </>
            ))}
          {/* <option key="oth-reason" value="other">
            Other
          </option> */}
        </select>
        {/* {other ? (
          <input
            className="form-control"
            type="text"
            name="reason"
            id="reason"
            placeholder="please specify here"
            onChange={handleOther}
          />
        ) : (
          ""
        )} */}
      </div>
    </>
  );
};

export default Reason;
