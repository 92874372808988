import { useState } from "react";
import useWindow from "../custom/useWindow";

const FormGuardian = ({ data, handleData }) => {
  const { width } = useWindow();
  const [guardian, setGuardian] = useState({
    name: "",
    relation: "",
    email: "",
    mobile: "",
    job: "",
    sosmed: "",
  });
  const handleGuardian = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    setGuardian({ ...guardian, [nam]: val });
    handleData({ guardian: { ...guardian, [nam]: val } });
  };
  if (data.type === "1")
    return (
      <>
        <div
          className="w-100"
          style={{
            textAlign: "center",
            fontSize: width > 450 ? "24px" : "18px",
            fontWeight: "500",
            color: "coral",
            padding: "25px 0 0 0",
          }}
        >
          GUARDIAN DATA <br />
          "Please choose who will you register."
        </div>
      </>
    );
  if (data.type === "3")
    return (
      <>
        <div
          className="w-100"
          style={{
            textAlign: "center",
            fontSize: width > 450 ? "24px" : "18px",
            fontWeight: "500",
            color: "coral",
            padding: "25px 0 0 0",
          }}
        >
          GUARDIAN DATA <br />
          "You can skip this part."
        </div>
      </>
    );
  return (
    <>
      <div
        className="w-100"
        style={{
          textAlign: "center",
          fontSize: width > 450 ? "24px" : "18px",
          fontWeight: "500",
          color: "coral",
          padding: "25px 0 0 0",
        }}
      >
        GUARDIAN DATA
      </div>
      <div className="w-100" style={{ padding: "25px" }}>
        <div className="w-100" style={{ lineHeight: "1" }}>
          <div className="__inputgroup">
            <label className="__inputlabel" htmlFor="name">
              name <b style={{ color: "#f99" }}>*</b>
            </label>
            <input
              className="form-control"
              type="text"
              name="name"
              placeholder="John Smith"
              required={true}
              value={guardian.name}
              onChange={handleGuardian}
            />
          </div>
          <div className="__inputgroup">
            <label className="__inputlabel" htmlFor="name">
              relation <b style={{ color: "#f99" }}>*</b>
            </label>
            <select
              className="form-select"
              name="relation"
              defaultValue=""
              required={true}
              onChange={handleGuardian}
            >
              <option key="0" value="">
                -
              </option>
              <option value="Mother">Mother</option>
              <option value="Father">Father</option>
              <option value="Others">Other</option>
            </select>
            {/* {guardian.relation === "Others" ? (
              <input
                className="form-control"
                type="text"
                name="relation"
                placeholder="please specify here"
                required={true}
                value={guardian.relation}
                onChange={handleGuardian}
              />
            ) : (
              ""
            )} */}
          </div>
          <div className="__inputgroup">
            <label className="__inputlabel" htmlFor="email">
              email <b style={{ color: "#f99" }}>*</b>
            </label>
            <input
              className="form-control"
              type="email"
              name="email"
              placeholder="john.smith@smi.com"
              required={true}
              value={guardian.email}
              onChange={handleGuardian}
            />
          </div>
          <div className="__inputgroup">
            <label className="__inputlabel" htmlFor="mobile">
              mobile <b style={{ color: "#f99" }}>*</b>
            </label>
            <input
              className="form-control"
              type="tel"
              name="mobile"
              placeholder="628112345"
              required={true}
              value={guardian.mobile}
              onChange={handleGuardian}
            />
          </div>
          <div className="__inputgroup">
            <label className="__inputlabel" htmlFor="job">
              job
            </label>
            <input
              className="form-control"
              type="text"
              name="job"
              placeholder="Content Creator"
              value={guardian.job}
              onChange={handleGuardian}
            />
          </div>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">@</div>
            </div>
            <label
              className="__inputlabel"
              htmlFor="sosmed"
              style={{ marginLeft: "40px", zIndex: "10" }}
            >
              ig, tiktok, fb, etc.
            </label>
            <input
              type="text"
              className="form-control"
              name="sosmed"
              placeholder="john.smith"
              value={guardian.sosmed}
              onChange={handleGuardian}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FormGuardian;
