import React, { useState } from "react";
import Question from "./question";
import Modal from "../website/modal";
import useWindow from "../custom/useWindow";
import { SendWa } from "../custom/apiResource";
import { apiSite } from "../config/urlLink";

const Page2 = () => {
  const { width } = useWindow();
  const [data, setData] = useState({
    id: 1,
    name: "",
    mobile: "",
    email: "",
    address: "",
    referal: "",
    location: "",
    profil: "",
  });
  const [vis, setVis] = useState({ modal: false });
  const handleChange = (e) => {
    e.preventDefault();
    let n = e.target.name;
    let v = e.target.value;
    // console.log(`${[e.target.name]}`, e.target.value);
    setData({ ...data, [n]: v });
  };
  const handleUp = (e) => {
    e.preventDefault();
    setData({ ...data, id: data.id - 1 });
    // console.log(data);
  };
  const handleDown = (e) => {
    e.preventDefault();
    setData({ ...data, id: data.id + 1 });
    // console.log(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const abortCtr = new AbortController();
    setVis({
      modal: true,
      title: "Submiting",
      msg: "Please wait while we're submiting your request.",
    });
    try {
      let obj = data;
      for (let key in obj) {
        if (obj[key] === "") {
          let errorobj = {
            error: "data",
            msg: (
              <>
                The value of <b> {key}</b> cannot empty.
              </>
            ),
          };
          throw errorobj;
        }
      }

      let fmsg = `FRANCHISE REQUEST :  
      Name : ${data.name}
      Address : ${data.address}
      Location Status : ${data.location}
      Mobile : ${data.mobile}
      Email : ${data.email}
      Investment profile : ${data.profil}
      Sincerely,
      ${data.name}
      `;
      let leadFormJson = {
        status: 'Lead',
        lead_name: data.name,
        company: 'Simfoni',
        email_id: data.email,
        mobile_no: data.mobile,
        notes: fmsg
      }
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "token f6b262554df61cb:ca71946b2def9fa",
      };
      let res = await fetch(
        `${apiSite}api/resource/Lead`,
        {
          signal: abortCtr.signal,
          method: "POST",
          body: JSON.stringify(leadFormJson),
          headers: headers,
        }
      );
      res = await res.json();
      console.log(res)
      let wa = await SendWa("628112975157", fmsg);
      wa = await SendWa("6287832340390", fmsg);

      if (wa.ok) {
        setVis({
          modal: true,
          title: "Submit",
          msg: "Franchise Request Submitted Successfully.",
        });
        setData({
          id: 1,
          name: "",
          mobile: "",
          email: "",
          address: "",
          referal: "",
          location: "",
          profil: "",
        });
      } else {
        throw wa;
      }
    } catch (error) {
      console.log(error);
      if (error.error === "data") {
        setVis({
          modal: true,
          title: "Error",
          msg: error.msg,
        });
      } else {
        let msg = `${JSON.stringify(data)} 
         ${JSON.stringify(error)}`;
        await SendWa("6287832340390", msg);
        setVis({
          modal: true,
          title: "Error",
          msg: "Ups sorry, there is an unexpected error. Please contact us on simfoni@sekolahmusikindonesia.id",
        });
      }
    }
  };
  const question = [
    {
      id: 1,
      type: "text",
      name: "name",
      label: "Please input your name below.",
      options: [],
    },
    {
      id: 2,
      type: "number",
      name: "mobile",
      label: "Your mobile / whatsapp number",
      options: [],
    },
    {
      id: 3,
      type: "email",
      name: "email",
      label: "Your email address",
      options: [],
    },
    {
      id: 4,
      type: "text",
      name: "address",
      label: "Where do you live.",
      options: [],
    },
    {
      id: 5,
      type: "text",
      name: "referal",
      label: "Who referred you about the smi partnership.",
      options: [],
    },
    {
      id: 6,
      type: "select",
      name: "location",
      label: "What is the status of the location you want to use as SMI",
      options: ["sewa", "pribadi"],
    },
    {
      id: 7,
      type: "select",
      name: "profil",
      label: "What is your investment profile",
      options: ["Low < 750Jt", "Med 750-1.5M", "High > 1.5M"],
    },
  ];
  return (
    <>
      <Modal
        modal={vis.modal}
        title={vis.title}
        element={
          <div
            className="w-100 justify-content-around"
            style={{
              textAlign: "justify",
              height: "auto",
            }}
          >
            {vis.msg}
          </div>
        }
        handleClose={() => setVis({ modal: false })}
      />
      {/* {console.log(data)} */}
      <div className="w-100" style={{ height: "25px" }}></div>
      <form
        className="col-md-6"
        onSubmit={handleSubmit}
        style={{
          color: "white",
          fontSize: "24px",
          height: width > 450 ? "40vh" : "80vh",
          overflowY: "auto",
        }}
      >
        <div className="w-100" style={{ height: "25px" }}></div>
        {question.map((e) => (
          <>
            <Question
              input={e}
              data={data}
              handleChange={handleChange}
              handleUp={handleUp}
              handleDown={handleDown}
            />
          </>
        ))}
        {question.length < data.id ? (
          <div className="w-100" style={{ fontSize: "18px" }}>
            <p>
              <b>FRANCHISE REQUEST : </b>
              <br />
              <br />
              <small>
                Name : {data.name}
                <br />
                Mobile : {data.mobile}
                <br />
                Email : {data.email}
                <br />
                Address : {data.address}
                <br />
                Partnership Referal : {data.referal}
                <br />
                Location Status : {data.location}
                <br />
                Investment profile : {data.profil}
                <br />
                <br />
                Sincerely, {data.name}
              </small>
            </p>
            <button className="btn btn-light" onClick={handleUp}>
              Back
            </button>
            <button className="btn btn-primary" type="submit">
              Submit
            </button>{" "}
          </div>
        ) : (
          ""
        )}
      </form>
    </>
  );
};

export default Page2;
